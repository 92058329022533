<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <TitleH2 title="Filtro de Serviço" />
        <ChipGroup
          ref="chipsServicos"
          :loading="loadingFiltros"
          :key="keyServico"
          :tags="servicos"
          @changeTag="onChangeServico"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="4">
        <TitleH2 title="Filtro de Filial"/>
        <AutocompletePaginadoMain
          ref="selectFiliais"
          tituloItem="nome"
          descricaoItem="cnpj"
          mascaraDescricao="##.###.###/####-##"
          firstIcon="card-account-details"
          placeholder="Filial"
          mensagemItemNaoEncontrado="Nenhuma filial encontrada"
          :filled="true"
          :rounded="true"
          :dense="true"
          :items="filiais"
          :key="keyParceiro"
          @update:value="onChangeFilial($event)"
          @scrollDown="adicionarMaisParceiros"
          @pesquisa="buscarFilialPorNome($event)"
          @onBlur="$event && $event.clear && receberFiliais()"
          @onClear="$event && $event.clear && receberFiliais()"
        ></AutocompletePaginadoMain>
      </v-col>

      <v-col cols="12" lg="4">
        <TitleH2 title="Filtro de Colaborador" />
        <AutoCompleteMain
          firstIcon="account"
          placeholder="Colaborador"
          searchKey="especializacao"
          :notFound="mensagemSelect || 'Primeiro selecione uma filial'"
          :loading="loadingFiltros"
          :key="keyColaborador"
          :data="colaboradores"
          @eventSelected="onChangeColaborador"
        />
      </v-col>

      <v-col cols="12" lg="4">
        <TitleH2 title="Filtro de Especialidade" />

        <AutocompletePaginadoMain
          ref="'selectEspecialidades'"
          tituloItem="descricao"
          descricaoItem="area"
          firstIcon="briefcase"
          placeholder="Especialidade"
          mensagemItemNaoEncontrado="Nenhuma especialidade encontrada"
          :exibirAvatar="false"
          :filled="true"
          :rounded="true"
          :dense="true"
          :items="especialidades"
          :key="keyEspecialidade"
          :keyName="'descricao'"
          @update:value="onChangeEspecialidade($event)"
          @scrollDown="adicionarEspecialidades"
          @pesquisa="buscarEspecialidadePorNome($event)"
          @onBlur="$event && $event.clear && receberEspecialidades()"
          @onClear="$event && $event.clear && receberEspecialidades()"
        ></AutocompletePaginadoMain>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <CalendarEvents
          :key="keyCalendario"
          :loading="loading"
          :agenda="agenda"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TitleH2 from '../../components/atoms/title/TitleH2'
import ChipMain from '@/components/atoms/items/ChipMain.vue'
import ChipGroup from '@/components/molecules/lists/ChipGroupMain.vue'
import AutoCompleteMain from '../../components/atoms/fields/AutocompleteMain'
import AutoCompleteEspecialidade from '../../components/atoms/fields/AutocompleteEspecialidade'
import AutocompletePaginadoMain from '../../components/atoms/fields/AutocompletePaginadoMain.vue'
import CalendarMain from '../../components/atoms/calendars/CalendarMain'
import TimelineListMain from '../../components/molecules/lists/TimelineListMain'
import api from '@/services/api'
import institucionalService from '@/services/InstitucionalService'
import colaboradorService from '@/services/ColaboradorService'
import globalMethods from '../../mixins/globalMethods.vue'
import CalendarEvents from '@/components/atoms/calendars/CalendarEvents.vue'

export default {
  components: {
    TitleH2,
    ChipMain,
    ChipGroup,
    AutoCompleteMain,
    CalendarMain,
    TimelineListMain,
    AutoCompleteEspecialidade,
    AutocompletePaginadoMain,
    CalendarEvents
  },

  data() {
    return {
      keyServico: 10,
      keyParceiro: 20,
      keyColaborador: 30,
      keyEspecialidade: 40,
      keyCalendario: 50,
      paginaAtualSelectParceiro: 0,
      paginaAtualSelectEspecialidade: 0,
      filiais: [],
      especialidades: [],
      colaboradores: [],
      agendaCompleta: [],
      colaboradorEncontrado: [],
      servicos: [],
      agenda: [],
      data: null,
      colaborador: null,
      servicoId: null,
      parceiroId: null,
      dataCalendar: null,
      disableChips: false,
      show: false,
      autocomplete: false,
      loading: false,
      loadingFiltros: false,
      servicoEstaSelecionado: false,
      mensagemErroPadrao: "Falha na conexão. Por favor, tente mais tarde.",
      mensagemSelect: "",
    }
  },

  mixins: [globalMethods],

  created() {
    this.loading = true
    this.loadingFiltros = true
    this.receberServicos();
    this.receberFiliais();
    this.receberEspecialidades();
    this.filtroInicialAgenda();
    this.loadingFiltros = false;
  },

  methods: {
    receberServicos() {
      api.getServicos().then(({ data }) => {
        this.servicos = data
      })
    },

    receberFiliais() {
      const paginacao = { page: 0, size: 10 };
      institucionalService
        .filiaisAtivas(paginacao)
        .then(({ data }) => {
          this.filiais = data.content;
        });
    },

    adicionarMaisParceiros() {
      const paginacao = { page: ++this.paginaAtualSelectParceiro, size: 10 };
      institucionalService
        .filiaisAtivas(paginacao)
        .then(({ data }) => {
          if(data.numberOfElements <= 0) {
            this.paginaAtualSelectParceiro = -1;
            return;
          }

          this.filiais = [...this.filiais, ...data.content];
      });
    },

    receberEspecialidades() {
      colaboradorService
        .especialidades({ page: 0, size: 10 })
        .then(({ data }) => {
          this.especialidades = data.content;
        });
    },

    adicionarEspecialidades() {
      const paginacao = { page: ++this.paginaAtualSelectEspecialidade, size: 10 };

      colaboradorService
        .especialidades(paginacao)
        .then(({ data }) => {
          if(data.numberOfElements <= 0) {
            this.paginaAtualSelectEspecialidade = -1;
            return;
          }

          this.especialidades = [ ...this.especialidades, ...data.content ];
      });
    },

    onChangeServico(servico) {
      this.$root.vtoast.close();
      this.servicoId === servico.id
        ? this.servicoEstaSelecionado = !this.servicoEstaSelecionado
        : this.servicoEstaSelecionado = true;

      if(!this.servicoEstaSelecionado) {
        this.filtroInicialAgenda();
        return;
      }

      this.loading = true;
      this.servicoId = servico.id;
      this.colaboradores = [];
      this.colaboradorEncontrado = [];
      const resestsSelectOnChange = { servico: false, parceiro: true, colaborador: true, especialidade: true };
      const resestsSelectErro = {
        servico: true, parceiro: true,
        colaborador: true, especialidade: true,
        calendario: true
      };
      this.resetarSelects(resestsSelectOnChange);
      const opcoesFiltro = { resestsSelectErro, contextoResetFiltro: 'inicio' };
      this.filtroAgenda({ servico: this.servicoId }, opcoesFiltro);
    },

    onChangeFilial(filialId) {
      this.$root.vtoast.close();
      this.filialId = filialId;
      this.colaboradores = [];
      this.mensagemSelect = "";
      const resestsSelectOnChange = { servico: true, parceiro: false, colaborador: true, especialidade: true };
      this.resetarSelects(resestsSelectOnChange);

      if(!filialId) {
        this.filtroInicialAgenda();
        return;
      }

      this.loading = true;
      this.filtroAgendaFilial(filialId);
    },

    onChangeColaborador(colaborador) {
      this.$root.vtoast.close();

      if(!colaborador) {
        this.mensagemErroPadrao = "";
        this.filtroAgenda({ parceiro: this.parceiroId }, {});
        return;
      }

      this.loading = true;
      this.colaborador = colaborador.id;
      const resestsSelectOnChange = { servico: true, parceiro: false, colaborador: false, especialidade: true };
      const resestsSelectErro = { servico: true, parceiro: false, colaborador: true, especialidade: true };
      this.resetarSelects(resestsSelectOnChange);
      const opcoesFiltro = { resestsSelectErro, contextoResetFiltro: 'colaborador' };
      this.filtroAgenda({ parceiro: this.parceiroId, colaborador: colaborador.id }, opcoesFiltro);
    },

    onChangeEspecialidade(especialidade) {
      this.$root.vtoast.close();
      this.colaboradores = [];
      const resestsSelectOnChange = { servico: true, parceiro: true, colaborador: true, especialidade: false };
      this.resetarSelects(resestsSelectOnChange);

      if(!especialidade) {
        this.filtroInicialAgenda();
        return;
      }

      this.loading = true;
      const resestsSelectErro = { servico: true, parceiro: true, colaborador: true, especialidade: true };
      const opcoesFiltro = { resestsSelectErro, contextoResetFiltro: 'inicio' };
      this.filtroAgenda({ especializacao: especialidade }, opcoesFiltro);
    },

    buscarEspecialidadePorNome(termo){
      if(!termo.length) {
        this.paginaAtualSelectEspecialidade = -1;
        this.receberEspecialidades();
        return;
      }

      colaboradorService
        .especialidadesPorDescricao({ descricao: termo })
        .then(({ data }) => {
          this.especialidades = data;
        });
    },

    buscarFilialPorNome(termo){
      if(!termo.length) {
        this.paginaAtualSelectParceiro = -1;
        this.receberFiliais();
        return;
      }

      institucionalService.filialPorNome(termo)
        .then(({ data }) => {
          this.filiais = data;
        });
    },

    filtroAgendaFilial(parceiroId) {
      const resestsSelectErro = { servico: true, parceiro: true, colaborador: true, especialidade: true };

      api.getAgendaFiltro({ parceiro: parceiroId }).then(({ data }) => {
          this.agendaCompleta = data.map((dia) => this.dateFormatISO(dia.data));
          this.agenda = data.map((_data) => {
            return {
              name: _data.servico,
              start: `${this.dateFormatISO(_data.data)}T${_data.horaInicial}`,
              end: `${this.dateFormatISO(_data.data)}T${_data.horaFinal}`,
              color: 'green',
              timed: true,
              id: _data.id
            }
          });

          this.buscarColaboradorPorPaceiro(parceiroId);
        })
        .catch((erro) => {
          this.resetarSelects(resestsSelectErro);
          this.resetarFiltro('inicio');
          this.agenda = [];
          this.agendaCompleta = [];
          this.mensagemSelect = "";

          const mensagemErro = erro.response.data;
          this.$root.vtoast.show({
            status: 'error',
            title: mensagemErro.titulo || this.mensagemErroPadrao,
            text: mensagemErro.mensagemUsuario || '',
          });
        })
        .then(() => this.loading = false);
    },

    buscarColaboradorPorPaceiro(parceiroId) {
      colaboradorService.colaboradorPorFilial(parceiroId)
        .then(({ data }) => {
          if(data.length <= 0) {
            this.mensagemSelect = "Nenhum colaborador associado a esta filial";
            return;
          }

          this.colaboradores = data.map(colaborador => {
            return {
              ...colaborador,
              avatar: `${process.env.VUE_APP_API_URL_DICEBEAR}?seed=${colaborador.nome}`
            }
          });
        })
        .catch((erro) => {
          const mensagemErro = erro.response.data;
          this.$root.vtoast.show({
            status: 'error',
            title: mensagemErro.titulo || this.mensagemErroPadrao,
            text: mensagemErro.mensagemUsuario || '',
          });
        });
    },

    filtroInicialAgenda() {
      api.getAgendaFiltro({}).then(({ data }) => {
        this.agenda = data.map((_data) => {
          return {
            name: _data.servico,
            start: `${this.dateFormatISO(_data.data)}T${_data.horaInicial}`,
            end: `${this.dateFormatISO(_data.data)}T${_data.horaFinal}`,
            color: 'green',
            timed: true,
            id: _data.id
          }
        })
      })
      .catch(() => this.agenda = [])
      .then(() => this.loading = false)
    },

    filtroAgenda(filtro, opcoes = { resestsSelectErro, contextoResetFiltro }) {
      api.getAgendaFiltro(filtro).then(({ data }) => {
          this.agendaCompleta = data.map((dia) => this.dateFormatISO(dia.data));
          this.agenda = data.map((_data) => {
            return {
              name: _data.servico,
              start: `${this.dateFormatISO(_data.data)}T${_data.horaInicial}`,
              end: `${this.dateFormatISO(_data.data)}T${_data.horaFinal}`,
              color: 'green',
              timed: true,
              id: _data.id
            }
          });
        })
        .catch((erro) => {
          this.resetarSelects(opcoes.resestsSelectErro);
          this.resetarFiltro(opcoes.contextoResetFiltro);
          this.agenda = [];
          this.agendaCompleta = [];
          this.mensagemSelect = "";

          const mensagemErro = erro.response.data;
          this.$root.vtoast.show({
            status: 'error',
            title: mensagemErro.titulo || this.mensagemErroPadrao,
            text: mensagemErro.mensagemUsuario || '',
          });
        })
        .then(() => this.loading = false);
    },

    resetarFiltro(filtro) {
      if(filtro === 'inicio') {
        this.filtroInicialAgenda();
        return;
      }

      if(filtro === 'colaborador') {
        this.filtroAgenda({ parceiro: this.parceiroId }, {});
        return;
      }
    },

    resetarSelects({ servico, parceiro, colaborador, especialidade, calendario }) {
      servico ? this.keyServico++ : null;
      parceiro ? this.keyParceiro++ : null;
      colaborador ? this.keyColaborador++ : null;
      especialidade ? this.keyEspecialidade++ : null;
      calendario ? this.keyCalendario++ : null;
    },

    selectedDate(data) {
      this.data = data

      if(this.colaborador) {
        api
          .getHorariosByData({
            data: this.data,
            servicoId: this.servicoId,
            colaborador: this.colaborador.id
          })
          .then(({ data }) => {
            this.colaboradorEncontrado = data.map((colaborador) => ({
              ...colaborador,
              avatar: `${process.env.VUE_APP_API_URL_DICEBEAR}?seed=${colaborador.nomeColaborador}`
            }))
          })
      } else {
        api
          .getHorariosByData(data, this.servicoId)
          .then(({ data }) => {
            this.colaboradorEncontrado = data.map((colaborador) => ({
              ...colaborador,
              avatar: `${process.env.VUE_APP_API_URL_DICEBEAR}?seed=${colaborador.nomeColaborador}`
            }))
          })
          .catch((_) => {
            this.colaboradorEncontrado = []
          })
      }
    },
  },
}
</script>

<style></style>
