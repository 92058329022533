<template>
  <div class="auto-complete">
    <v-autocomplete
      v-if="!loading"
      :disabled="disabled"
      :items="data"
      clearable
      dense
      filled
      :item-value="'id'"
      hide-details
      :placeholder="placeholder"
      rounded
      @change="show"
      return-object
    >
      <template v-slot:no-data>
        <span class="pa-2 grey--text lighten-1">{{ notFound }}</span>
      </template>

      <template v-slot:selection="data">
        <v-chip>
          <label>{{ data.item.descricao }}</label>
        </v-chip>
      </template>

      <template v-slot:item="data">
        <template>
          <v-list-item-content>
            <v-list-item-title>
              {{ data.item.descricao }}
            </v-list-item-title>
            <v-list-item-subtitle
              v-html="data.item.area.descricao"
            ></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>

      <template v-slot:prepend-inner>
        <v-icon color="grey lighten-1">mdi-{{ firstIcon }}</v-icon>
      </template>
    </v-autocomplete>
    <v-skeleton-loader v-else type="text"></v-skeleton-loader>
  </div>
</template>

<script>
import AlertMain from '../AlertMain.vue'

export default {
  components: {
    AlertMain
  },
  props: {
    placeholder: String,
    data: null,
    loading: false,
    disabled: null,
    notFound: { default: 'Nada encontrado!' },
    firstIcon: null,
    searchKey: null
  },
  data() {
    return {
      fieldSelected: null
    }
  },
  methods: {
    show(e) {
      this.$emit('changeEspecialidade', e)
    }
  }
}
</script>

<style></style>
