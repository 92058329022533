<template>
  <div>
    <v-row v-if="!loading" class="fill-height">
      <v-col>
        <v-sheet height="64">
          <v-toolbar flat>
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small> mdi-chevron-right </v-icon>
            </v-btn>
            <v-toolbar-title class="uppercase" v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              class="mr-4"
              color="grey darken-2"
              @click="setToday"
            >
              hoje
            </v-btn>
            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'day'">
                  <v-list-item-title>DIA</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = '4day'">
                  <v-list-item-title>4 DIAS</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'week'">
                  <v-list-item-title>SEMANA</v-list-item-title>
                </v-list-item>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>MÊS</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
        </v-sheet>
        <v-sheet height="600">
          <v-calendar
            locale="pt"
            event-more-text="mais {0} eventos"
            ref="calendar"
            v-model="focus"
            color="primary"
            :first-interval="6"
            :interval-height="30"
            event-overlap-mode="column"
            :events="agenda"
            :event-color="getEventColor"
            :type="type"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
          ></v-calendar>
          <v-menu
            v-model="selectedOpen"
            offset-x
            :close-on-content-click="false"
            :close-on-click="false"
            :max-width="550"
            :activator="selectedElement"
          >
            <v-card v-if="!loadingCard" color="grey lighten-4">
              <v-toolbar :color="selectedEvent.color" dark>
                <v-toolbar-title class="title-toolbar">
                  {{ selectedEvent.name }}
                  <small>{{ selectedEvent.time }}</small>
                </v-toolbar-title>
              </v-toolbar>

              <v-card elevation="2">
                <v-list-item>
                  <v-list-item-avatar size="80" rounded="" color="green">
                    <v-img :src="colaborador.avatar"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="black--text mb-3">
                      {{ colaborador.nomeColaborador }}
                      <v-list-item-subtitle class="mb-2">{{
                        colaborador.descricaoEspecializacao
                      }}</v-list-item-subtitle>
                      <v-chip small class="green white--text"
                        >{{ colaborador.descricaoDia }}
                        {{ colaborador.data }}</v-chip
                      >
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <div
                  v-if="
                    !mudarStatus &&
                    !mostarStatusDisponivel &&
                    !abrirFiltoAssociado
                  "
                >
                  <v-card-text>
                    <v-list-item-subtitle
                      v-for="horario in colaborador.horarios"
                      :key="horario.horaInicio"
                    >
                      <v-chip class="ma-1">{{ horario.horaInicio }}</v-chip>
                      as
                      <v-chip class="ma-1">{{ horario.horaFim }}</v-chip>

                      <v-chip class="ma-1">
                        <v-icon dense left>mdi-calendar</v-icon>
                        {{ horario.status }}
                      </v-chip>

                      <v-chip class="ma-1">
                        <v-icon dense left>mdi-currency-usd</v-icon>
                        {{ horario.statusPagamento }}
                      </v-chip>
                    </v-list-item-subtitle>
                  </v-card-text>
                </div>
                <div
                  v-if="
                    mudarStatus &&
                    !mostarStatusDisponivel &&
                    !abrirFiltoAssociado
                  "
                >
                  <v-card-text>
                    <v-list-item-subtitle
                      v-for="horario in colaborador.horarios"
                      :key="horario.horaInicio"
                    >
                      <v-chip class="ma-1">{{ horario.horaInicio }}</v-chip>
                      as
                      <v-chip class="ma-1">{{ horario.horaFim }}</v-chip>

                      <v-chip class="ma-1">
                        <v-icon dense left>mdi-calendar</v-icon>
                        {{ horario.status }}
                      </v-chip>

                      <v-chip class="ma-1">
                        <v-icon dense left>mdi-currency-usd</v-icon>
                        {{ horario.statusPagamento }}
                      </v-chip>
                      <v-btn
                        class="ma-1"
                        icon
                        small
                        rounded
                        @click="receberStatusAgendamento(horario)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </v-list-item-subtitle>
                  </v-card-text>
                </div>
                <div
                  v-if="
                    mudarStatus &&
                    mostarStatusDisponivel &&
                    !abrirFiltoAssociado
                  "
                >
                  <v-card-text>
                    <span class="label-list-title">Atenção!</span>
                    <ul class="aviso-cancelamento-agendamento">
                      <li>Só haverá estorno de pagamento para cancelamentos executados com  mais de 24h de antecedência para a realização do agendamento.</li>
                      <li>Cancelamento com menos de 24h não é permitido.</li>
                    </ul>
                    <span class="subtitle-1">
                      Selecione um novo status para o horário de
                      <strong>{{ horarioEscolhido.horaInicio }}</strong> às
                      <strong>{{ horarioEscolhido.horaFim }}</strong></span
                    >
                    <v-skeleton-loader
                      v-if="statusLoading"
                      type="chip@2"
                    ></v-skeleton-loader>
                    <v-chip-group v-else active-class="white--text green">
                      <v-chip
                        v-for="status in listaStatus"
                        :key="status.id"
                        @click="
                          novoStatus = status.id
                          abrirFiltro()
                        "
                      >
                        {{ status.descricao }}
                      </v-chip>
                    </v-chip-group>
                  </v-card-text>

                </div>
                <div
                  v-if="
                    mudarStatus && mostarStatusDisponivel && abrirFiltoAssociado
                  "
                >
                  <v-card-text max-width="500">
                    <span class="subtitle-1">
                      Selecione o associado pelo seu cpf.
                    </span>
                    <v-text-field
                      v-model="filtro.cpf"
                      name="CPF"
                      label="CPF"
                      id="id"
                      outlined
                      :append-icon="'mdi-send'"
                      @click:append="filtrarAssociado()"
                    ></v-text-field>
                    <span v-if="mostarDados" class="subtitle-1">
                      Deseja agendar o horario de
                      {{ horarioEscolhido.horaInicio }} às
                      {{ horarioEscolhido.horaFim }} <br />
                      para o associado
                      {{ dadosAssociado.nome }}?
                    </span>
                  </v-card-text>
                </div>
                <v-card-actions>
                  <v-btn text color="red darken-3" @click="reset()">
                    Fechar
                  </v-btn>
                  <v-btn
                    v-if="!mostarStatusDisponivel"
                    text
                    color="blue darken-3"
                    @click="mudarStatus = !mudarStatus"
                  >
                    Editar
                  </v-btn>
                  <v-btn
                    v-if="mostarStatusDisponivel && !abrirFiltoAssociado"
                    text
                    color="blue darken-3"
                    @click="
                      mudarStatusApi(),
                        (mudarStatus = false),
                        (mostarStatusDisponivel = false)
                    "
                  >
                    salvar
                  </v-btn>
                  <v-btn
                    v-if="
                      mostarStatusDisponivel &&
                      abrirFiltoAssociado &&
                      mostarDados
                    "
                    text
                    color="blue darken-3"
                    @click="agendarHorario()"
                  >
                    Confirmar
                  </v-btn>
                </v-card-actions>
              </v-card>

              <DialogConfirmation
                :key="keyDialogEstorno"
                :tag="'edit'"
                :width="650"
                :title="'Aviso de Estorno'"
                :show="dialogEstorno"
                @close="dialogEstorno = false; keyDialogEstorno++"
                @confirm="confirmarCancelamentoAgendamentoComEstorno()"
              >
                <template v-slot:body>
                  <div class="aviso-estorno">
                    Este agendamento já foi pago. O cancelamento provocará o estorno do pagamento. Confirma a operação?
                    </div>
                </template>
              </DialogConfirmation>
            </v-card>

            <v-skeleton-loader
              width="400"
              v-else
              type="list-item-avatar-three-line, article"
            ></v-skeleton-loader>
          </v-menu>
        </v-sheet>
      </v-col>
    </v-row>
    <div v-else v-for="index in 5" :key="index" class="d-flex justify-center">
    </div>
  </div>
</template>
<script>
import globalMethods from '@/mixins/globalMethods'
import DialogConfirmation from '../../molecules/dialogs/DialogConfirmation.vue'
import api from '@/services/api'

export default {
  components: { DialogConfirmation },

  data() {
    return {
      keyDialogEstorno: 0,
      dialogEstorno: false,
      mostarDados: false,
      abrirFiltoAssociado: false,
      eventoId: '',
      novoStatus: '',
      horarioEscolhido: [],
      mostarStatusDisponivel: false,
      mudarStatus: false,
      listaStatus: [],
      statusLoading: false,
      focus: '',
      loadingCard: false,
      type: 'month',
      typeToLabel: {
        month: 'Mês',
        week: 'Semana',
        day: 'Dia',
        '4day': '4 Dias'
      },
      colaborador: [],
      selectedEvent: {},
      selectedElement: null,
      dataAgendamentoSelecionado: null,
      horarioInicioAgendamentoSelecionado: null,
      statusPagamentoAgendamentoSelecionado: null,
      statusPagamentoSelecionado: null,
      selectedOpen: false,
      events: [],
      colors: ['green'],
      names: [
        'Meeting',
        'Holiday',
        'PTO',
        'Travel',
        'Event',
        'Birthday',
        'Conference',
        'Party'
      ],
      filtro: {
        nome: '',
        cpf: '',
        status: '',
        numeroOab: '',
        atualizado: ''
      },
      pagination: {
        page: 0,
        perPage: 10,
        total: 0
      },
      dadosAssociado: {}
    }
  },
  props: {
    agenda: Array,
    loading: Boolean
  },

  mixins: [globalMethods],

  methods: {
    agendarHorario() {
      const data = {
        associado: this.dadosAssociado.id,
        id: this.horarioEscolhido.id
      }
      api.putAgendarHorario(data).then(() => {
        api.getAgendaById(this.eventoId)
          .then(({ data }) => {
            this.colaborador = data.map((colaborador) => ({
              ...colaborador,
              avatar: `${process.env.VUE_APP_API_URL_DICEBEAR}?seed=${colaborador.nomeColaborador}`
            }))[0]
          })
        this.reset()
        this.$root.vtoast.show({
          status: 'success',
          title: 'Sucesso!',
          text: 'Horario agendado com sucesso.',
        })
      })
    },

    abrirFiltro() {
      if (this.novoStatus == 2) {
        this.abrirFiltoAssociado = true
      }
    },

    async filtrarAssociado() {
      this.filtro.cpf = this.filtro.cpf.replace(/[^0-9]/g, '');
      await api
        .getAssociadoFiltro(this.pagination, this.filtro)
        .then(({ data }) => {
          this.mostarDados = true
          this.dadosAssociado = data.content[0]
          if (!data.content[0]) {
            this.mostarDados = false;
            this.$root.vtoast.show({
              status: 'error',
              title: 'Erro!',
              text: 'Não foi encontrado nenhum associado com esse CPF.',
            })
          }
        })
        .catch((err) => {
          this.mostarDados = false
          this.$root.vtoast.show({
            status: 'error',
            title: 'Erro!',
            text: 'Não foi encontrado nenhum associado com esse CPF.',
          })
        })
    },

    verificarSeNaoPodeCancelarAgendamento(statusAgendamento, horasAteAgendamento){
      return statusAgendamento === 4 && this.statusPagamentoAgendamentoSelecionado === "CONFIRMADO" && horasAteAgendamento < 24;
    },

    mudarStatusApi() {
      if (this.novoStatus != '') {
        const dataEHoraAgendamento = `${this.dataAgendamentoSelecionado} ${this.horarioInicioAgendamentoSelecionado}`;
        const horasAteAgendamento = this.diferencaTempoAgoraAteDataEspecificada(dataEHoraAgendamento, 'hours');
        if(this.verificarSeNaoPodeCancelarAgendamento(this.novoStatus, horasAteAgendamento)) {
          this.$root.vtoast.show({
            status: 'warning',
            title: 'ATENÇÃO',
            text: 'Agendamentos com pagamento já relalizado e com menos de 24h antes do horário agendado não podem ser cancelados.',
            time: 30000
          });
          this.statusPagamentoAgendamentoSelecionado = null;
          this.novoStatus = '';
          return;
        }

        if(this.novoStatus === 4 && this.statusPagamentoAgendamentoSelecionado === "CONFIRMADO" && horasAteAgendamento >= 24) {
          this.abrirDialogEstorno();
          return;
        }

        const dataEHoraAtual = this.horaAtual('America/Sao_Paulo', 'dd/MM/yyyy HH:mm');
        this.atualizarStatus(this.horarioEscolhido.id, this.novoStatus, { dataCancelamento: dataEHoraAtual });
      } else {
        this.mostarStatusDisponivel = true;
        this.mudarStatus = true;
        this.abrirFiltoAssociado = false;
        this.$root.vtoast.show({
          status: 'warning',
          title: 'Nenhum status selecionado!',
          text: 'Selecione o novo status do agendamento.',
          time: 20000
        })
      }
    },

    receberStatusAgendamento(horario) {
      this.statusPagamentoAgendamentoSelecionado = horario.statusPagamento ? horario.statusPagamento : null
      this.horarioInicioAgendamentoSelecionado = horario.horaInicio
      this.statusLoading = true
      this.mostarStatusDisponivel = true
      this.horarioEscolhido = horario
      api
        .getStatusHorario(horario.idStatus)
        .then(({ data }) => {
          this.listaStatus = data
          this.statusLoading = false
        })
        .catch(() => {
          this.statusLoading = false
        })
    },

    abrirDialogEstorno() {
      this.dialogEstorno = true;
    },

    confirmarCancelamentoAgendamentoComEstorno(){
      const dataEHoraAtual = this.horaAtual('America/Sao_Paulo', 'dd/MM/yyyy HH:mm');
      this.atualizarStatus(this.horarioEscolhido.id, this.novoStatus, { dataCancelamento: dataEHoraAtual });
    },

    atualizarStatus(horarioEscolhidoId, novoStatus, paramnetros) {
      api.putStatusHorario(horarioEscolhidoId, novoStatus, paramnetros)
        .then(() => {
          api.getAgendaById(this.eventoId).then(({ data }) => {
            this.colaborador = data.map((colaborador) => ({
              ...colaborador,
              avatar: `${process.env.VUE_APP_API_URL_DICEBEAR}?seed=${colaborador.nomeColaborador}`
            }))[0];
          });
          this.$root.vtoast.show({
            status: 'success',
            title: 'Sucesso!',
            text: 'Status alterado com sucesso.',
          });

          this.dialogEstorno = false;
          this.keyDialogEstorno++;
          this.mostarStatusDisponivel = false;
        })
        .catch((erro) => {
          const mensagemErro = erro.response?.data;
          this.$root.vtoast.show({
            status: 'error',
            title: mensagemErro?.titulo || this.mensagemErroPadrao,
            text: mensagemErro?.mensagemUsuario || '',
            time: 30000
          });
        })
        .then(() => {
          this.statusPagamentoAgendamentoSelecionado = null;
          this.novoStatus = '';
        });
    },

    viewDay({ date }) {
      this.focus = date
      this.type = 'day'
    },

    getEventColor(event) {
      return event.color
    },

    setToday() {
      this.focus = ''
    },

    prev() {
      this.$refs.calendar.prev()
    },

    next() {
      this.$refs.calendar.next()
    },

    async showEvent({ nativeEvent, event }) {
      this.eventoId = event.id
      this.loadingCard = true
      const { data } = await api.getAgendaById(event.id)
      this.dataAgendamentoSelecionado = data[0].data;
      this.colaborador = data.map((colaborador) => ({
        ...colaborador,
        avatar: `${process.env.VUE_APP_API_URL_DICEBEAR}?seed=${colaborador.nomeColaborador}`
      }))[0];

      const open = () => {
        this.selectedEvent = event
        this.selectedEvent.time = `${this.getTime(event.start)} - ${this.getTime(event.end)}`
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => (this.selectedOpen = true)))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }
      this.loadingCard = false

      this.selectedOpen = false
      this.mudarStatus = false
      this.mostarStatusDisponivel = false
      this.abrirFiltoAssociado = false
      this.mostarDados = false
      this.dadosAssociado = {}
      this.filtro.cpf = ''

      nativeEvent.stopPropagation()
    },

    reset() {
      this.selectedOpen = false
      this.mudarStatus = false
      this.mostarStatusDisponivel = false
      this.abrirFiltoAssociado = false
      this.mostarDados = false
      this.dadosAssociado = {}
      this.filtro.cpf = ''
    }
  }
}
</script>
<style scoped>
  .uppercase {
    text-transform: uppercase !important;
  }

  .label-list-title {
    color: #C62828;
    display: flex;
    font-size: 1.2rem;
    text-transform: uppercase;
    margin-bottom: 0.6rem;
  }

  .aviso-cancelamento-agendamento {
    color: #C62828 ;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    font-size: 1rem;
    padding-left: 0.6rem;
  }

  .aviso-cancelamento-agendamento > li {
    list-style-type: '* ';
  }

  .aviso-cancelamento-agendamento > li:not(:last-child) {
    margin-bottom: 0.4rem;
  }

  .aviso-estorno {
    font-size: 1.1rem;
    text-transform: uppercase;
    padding-top: 1rem;
  }
</style>
